<template>
  <div class="consortium">
    <!-- <div class="top_Img"> 
      <img :src="pageImg" alt="">
      <div class="tips">{{pageTitle}}</div>
    </div> -->
    <div v-if="loading" class="spin-loading">
      <a-spin tip="Loading..." size="large">
        <div class="spin-content"></div>
      </a-spin>
    </div>
    <div v-else>
      <div class="items" v-if="listData.length !=0">
        <div class="list" v-for="(item,index) of listData" :key="index" @click="handleDetails(item.id)">
          <div class="Img">
            <img :src="item.cover" alt="">
          </div>
          <div class="message">
            <div class="title1">{{item.title}}</div>
            <div class="title2" v-html="item.content">{{item.content}}</div>
            <div class="time">{{moment(item.pubdate).format('YYYY-MM-DD')}}</div>
          </div>
        </div>
      </div>
      <div v-else style="text-align:center;margin-top:80px;">
        <img src="../../assets/nodata.jpg" alt="">
        <div class="tips">暂无文章</div>
      </div>
      <!-- 分页 -->
      <div class="pagination" v-if="listData.length != 0">
        <a-pagination :current="defaultCurrent" :total="total" @change="changePage" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data(){
    return {
      moment,
      searchMessage:{
        page_index:1,
        per_page_count:10,
      },
      total:1, // 分页数据总数
      listData:[], // 数据
      pageImg:'',
      pageTitle:'',
      defaultCurrent:1,

      loading:true,
    }
  },
  watch:{
    $route(){
      this.searchMessage.page_index = 1;
      this.defaultCurrent = 1;
      this.getDataList();
    }
  },
  methods:{
    // 查看详情
    handleDetails(id){
      this.$router.push({path:'messageDetails',query:{isTop:'Top',detailID:id}});
    },
    // 查询数据接口
    getDataList(){
      let ID = this.$route.query.id;
      this.$post('/v1/news/column/' + ID,this.searchMessage).then(res=>{
        this.total = res.all_news_count;
        res.data.forEach(item=>{
          if(item.content){
            item.content = item.content.replace(/<\/?(img)[^>]*>/gi, '').replace(/<\/?(iframe)[^>]*>/gi,'').replace(/<\/?(video)[^>]*>/gi,'');
          }
        })
        this.$root.$emit('changeImg',ID);
        this.listData = res.data;
        this.pageImg = res.url;
        this.pageTitle = res.title;
        setTimeout(() => {
          this.loading = false
        }, 500);
      })
    },
    // 页码查询
    changePage(page,pageSize){
      this.searchMessage.page_index = page;
      this.defaultCurrent = page;
      window.scrollTo(0,0)
      this.getDataList();
    }
  },
  mounted(){
    this.getDataList();
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .ant-pagination-item-active a{color: #fff;}
  .consortium{
    .items{padding: 12px;margin:0 auto;margin-top:20px;max-width: 1400px;
      .list{display: flex;border-bottom:1px solid #ececec;margin-bottom:15px;padding-bottom:15px;height:146px;cursor: pointer;
        img{width: 200px;height: 130px;}
        .message{margin-left: 30px;display: flex;flex-direction: column;justify-content: space-between;
        }
      }
    }
    .title1{font-size: 20px;font-weight: bold;color: #333;}
    .title2,.time{font-size: 12px;color: #333;}
    .pagination{max-width: 1400px;margin: 0 auto;display: flex;justify-content: flex-end;}
  }
</style>